import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";


class Footer extends React.Component {
    render() {
        return (
            <div className={"footer section-padding"}>
                <Container>
                    <Row>
                        <Col md={6} sm={6} className={"footer-sitemap"}>
                            <div className={"footer-title"}>
                                <h5>SITEMAP</h5>
                            </div>
                            <div className={"sitemap"}>
                                <ul>
                                    <li><Link to={"/"}>Home</Link></li>
                                    <li><Link to={"/blog"}>Blog</Link></li>
                                    <li><Link to={"/projects"}>Projects</Link></li>
                                    <li><Link to={"/tags"}>Tags</Link></li>
                                    <li><Link to={"/contact"}>Contact</Link></li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={6} sm={6} className={"footer-social"}>
                            <div className={"footer-title"}>
                                <h5>FOLLOW ME</h5>
                            </div>
                            <ul className={"social-icons"}>
                                <li><a href="http://twitter.com/mhmdhallak"><i aria-label={"Twitter"} className={"fab fa-twitter"}></i></a></li>
                                <li><a href="https://www.linkedin.com/in/mhmdhallak/"><i aria-label={"LinkedIn"} className={"fab fa-linkedin-in"}></i></a></li>
                                <li><a href="http://github.com/mrhallak"><i aria-label={"Github"} className={"fab fa-github"}></i></a></li>
                                <li><a href="https://www.kaggle.com/mhmdhallak"><i aria-label={"Kaggle"} className={"fab fa-kaggle"}></i></a></li>
                                <li><a href="https://stackoverflow.com/users/6725706/mrhallak"><i aria-label={"Stack Overflow"} className={"fab fa-stack-overflow"}></i></a></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className={"copyright"}>
                                <p className={"footer-title"}>Copyright © 2021 <a href="http://hallak.io">Mohamad Hallak</a></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Footer;
