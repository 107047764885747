import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";

class Seo extends React.Component {
    render() {
        return (
            <StaticQuery
                query={
                    graphql`
                        query HeadingQuery {
                            site {
                                siteMetadata {
                                    title
                                    description
                                    siteUrl
                                    author
                                }
                            }
                        }
                    `}
                render={data => (
                    <div className="application">
                        <Helmet
                            htmlAttributes={{
                                lang: `en`,
                            }}
                        >
                            <title>{this.props.pageName ? this.props.pageName + " - " + data.site.siteMetadata.title : data.site.siteMetadata.title}</title>
                            <meta name="description" content={data.site.siteMetadata.description} />
                            <meta name="robots" content="index, follow" />
                            <meta name="twitter:site" content={data.site.siteMetadata.twitterUsername} />
                            <meta name="twitter:creator" content={data.site.siteMetadata.twitterUsername} />
                            <meta property="og:title" content={this.props.pageName ? this.props.pageName + " - " + data.site.siteMetadata.title : data.site.siteMetadata.title} />
                            <meta property="og:description" content={data.site.siteMetadata.description} />
                            <meta property="og:site_name" content={data.site.siteMetadata.siteUrl} />
                            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

                            <script defer src="https://www.googletagmanager.com/gtag/js?id=UA-143824484-1"></script>
                            <script defer>
                                {`
                                window.dataLayer = window.dataLayer || [];
                                function gtag(){dataLayer.push(arguments);}
                                gtag('js', new Date());

                                gtag('config', 'UA-143824484-1', { 'optimize_id': 'GTM-TK3JCT4'});
                            `}
                            </script>

                            <script src="https://kit.fontawesome.com/b86f5b246d.js" crossorigin="anonymous" async></script>

                        </Helmet>
                    </ div>
                )}
            />
        )
    }
}

export default Seo;
